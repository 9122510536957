<template>
	<q-card
		class="q-pa-md"
		style="width: 700px; max-width: 80vw"
	>
		<q-stepper
			v-model="step"
			ref="stepper"
			color="primary"
			animated
			flat
			class="q-pa-none"
		>
			<q-step
				:name="1"
				title="Select condition"
				:done="step > 1"
				class="q-pa-none"
			/>
			<q-step
				:name="2"
				title="Select action"
				:done="step > 2"
				class="q-pa-none"
			/>
		</q-stepper>
		<div v-if="current_display.includes('condition_')">
			<q-item
				:class="
					['condition_value_attachment', 'condition_value_checkbox'].includes(current_display) ? 
					'flex items-center' : 'flex items-baseline q-pa-none'
				"
				:tag="['condition_value_attachment', 'condition_value_checkbox'].includes(current_display) ? 'label' : ''"
			>
				<q-select v-if="current_display === 'condition_type'"
					class="full-width"
					v-model="selected_condition"
					:options="conditions_list"
					label="Add new condition"
					:error="has_base_error"
					:error-message="base_error_message"
					@input="selectCondition"
				/>
				<q-select v-if="current_display === 'condition_fg'"
					class="full-width"
					v-model="condition_fg_selection"
					:options="fg_options"
					:label="title_text"
					ref="condition_fg"
					@popup-hide="openItemsCondition()"
					:error="has_base_error"
					:error-message="base_error_message"
					:option-label="(item) => item.title"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesCondition()" class="cursor-pointer close-btn"/>
					</template>
				</q-select>
				<q-select v-if="current_display === 'condition_field'"
					class="full-width"
					v-model="condition_field_selection"
					:options="field_options"
					:label="title_text"
					ref="condition_field"
					@popup-hide="openItemsCondition()"
					:error="has_base_error"
					:error-message="base_error_message"
					:option-label="(item) => item.label"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesCondition()" class="cursor-pointer close-btn"/>
					</template>
				</q-select>
				<q-select v-if="current_display === 'condition_value_multiselect' || last_condition === 'condition_value_multiselect'"
					class="full-width"
					v-model="condition_value_selection"
					:options="value_options"
					:label="condition_field_selection.label + ' / ' + title_text"
					ref="condition_value_multiselect"
					@popup-hide="openItemsCondition()"
					:error="has_select_error"
					:error-message="select_error_message"
					:option-label="(item) => item.value"
					:disable="is_condition_readonly"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesCondition()" class="cursor-pointer close-btn"/>
					</template>
				</q-select>
				<div
					class="text-left full-width row"
					v-if="current_display === 'condition_value_checkbox'  || last_condition === 'condition_value_checkbox'"
				>
					<div style="width:50%;" class="flex items-center">
						{{ condition_field_selection.label }} / {{title_text}}
					</div>
					<div style="width:50%;" class="text-right">
						<q-toggle v-model="condition_checkbox_value" :disable="is_condition_readonly"/>
					</div>
				</div>
				<div class="text-left full-width row items-center" style="height: 100%;" v-if="current_display == 'condition_value_attachment' || last_condition === 'condition_value_attachment'">
					<div style="width:70%;" class="flex items-center">
						{{ condition_field_selection.label }} {{ title_text }}
					</div>
					<div style="width:30%;" class="text-right">
						<q-toggle v-model="condition_attachment_value" :disable="is_condition_readonly"/>
					</div>
				</div>
				<q-input v-if="current_display === 'condition_value_datetime' || last_condition === 'condition_value_datetime'"
					v-model="condition_input_value"
					class="full-width"
					type="text"
					:label="condition_field_selection.label + ' / ' + title_text"
					@click="date_time_dialog=true"
					:disable="is_condition_readonly"
					:error="has_input_error"
					:error-message="input_error_message"
					hint="Format DD/MM/YYYY HH:MM:SS"
				>
					<template v-slot:prepend>
						<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog=true"/>
					</template>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesCondition()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>
				<!-- DATE -->
				<q-input v-if="current_display === 'condition_value_date' || last_condition === 'condition_value_date'"
					v-model="condition_input_value"
					class="full-width"
					type="text"
					:label="condition_field_selection.label + ' / ' + title_text"
					@click="show_date_modal=true"
					:error="has_input_error"
					:error-message="input_error_message"
					:disable="is_condition_readonly"
					hint="Format DD/MM/YYYY"
				>
					<template v-slot:prepend>
						<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal=true"/>
					</template>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesCondition()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>
				<!-- TIME -->
				<q-input v-if="current_display === 'condition_value_time' || last_condition === 'condition_value_time'"
					v-model="condition_input_value"
					class="full-width"
					type="text"
					:label="condition_field_selection.label + ' / ' + title_text"
					@click="show_time_modal=true"
					:error="has_input_error"
					:error-message="input_error_message"
					:disable="is_condition_readonly"
					hint="Format HH:MM:SS"
				>
					<template v-slot:prepend>
						<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal=true"/>
					</template>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesCondition()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>
				<!-- NUMBER -->
				<q-input v-if="current_display === 'condition_value_number' || last_condition == 'condition_value_number'"
					v-model="condition_input_value"
					type="number"
					class="full-width"
					:label="condition_field_selection.label + ' / ' + title_text"
					:error="has_input_error"
					:error-message="input_error_message"
					onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
					:disable="is_condition_readonly"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesCondition()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>
				<q-input v-if="current_display === 'condition_value_input' || last_condition === 'condition_value_input'"
					v-model="condition_input_value"
					class="full-width"
					type="text"
					:label="condition_field_selection.label + ' / ' + title_text"
					:error="has_input_error"
					:error-message="input_error_message"
					:disable="is_condition_readonly"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesCondition()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>
				<q-select v-if="current_display === 'condition_status' || last_condition === 'condition_status'"
					class="full-width"
					v-model="conditions_status_selection"
					:options="statuses"
					:label="title_text"
					ref="condition_status"
					:error="has_condition_status_error"
					:error-message="condition_status_error_message"
					@popup-hide="openItemsCondition()"
					:disable="is_condition_readonly"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesCondition()" class="cursor-pointer close-btn"/>
					</template>
				</q-select>
				<q-btn
					v-if="
						!current_display.includes('action_') && 
						['condition_value_checkbox', 'condition_value_attachment'].includes(current_display)
					"
					round
					flat
					color="dark"
					icon="close"
					size="sm"
					style="height: fit-content;"
					@click="resetValuesCondition()"
				/>
				<q-dialog v-model="date_time_dialog">
					<div
						class="q-pa-md date-time-container"
						:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
						:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
					>
						<div class="q-gutter-md row items-start date-time">
							<q-date
								v-model="condition_input_value"
								mask="DD/MM/YYYY HH:mm:ss"
								color="primary"
							/>
							<q-time
								format24h
								v-model="condition_input_value"
								mask="DD/MM/YYYY HH:mm:ss"
								color="primary"
							/>
						</div>
						<div class="q-mt-md flex justify-end full-width">
							<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
						</div>
					</div>
				</q-dialog>
				<q-dialog v-model="show_date_modal">
					<q-date
						today-btn
						v-model="condition_input_value"
						mask="DD/MM/YYYY"
						color="primary"
					>
						<div class="row items-center justify-end">
							<q-btn v-close-popup label="Close" color="primary" flat />
						</div>
					</q-date>
				</q-dialog>
				<q-dialog v-model="show_time_modal">
					<q-time
						format24h
						v-model="condition_input_value"
						mask="HH:mm:ss"
						color="primary"
					>
						<div class="row items-center justify-end">
							<q-btn v-close-popup label="Close" color="primary" flat />
						</div>
					</q-time>
				</q-dialog>
			</q-item>
			<div class="row items-center justify-end">
				<q-btn v-close-popup label="Cancel" color="primary" flat />
				<q-btn
					label="Continue"
					@click="condition_accept_value()"
					color="primary"
					flat
					:disable="
						![
							'condition_status',
							'condition_value_input',
							'condition_value_date',
							'condition_value_time',
							'condition_value_datetime',
							'condition_value_checkbox',
							'condition_value_attachment',
							'condition_value_number',
							'condition_value_multiselect'
						].includes(current_display)
					"
				/>
			</div>
		</div>
		<div v-if="current_display.includes('action_')" class="full-width">
			<q-item
				:class="
					['action_value_attachment', 'action_value_checkbox'].includes(current_display) ? 
					'flex items-center' : 'flex items-baseline q-pa-none'
				"
				:tag="['action_value_attachment', 'action_value_checkbox'].includes(current_display) ? 'label' : ''"
			>
				<q-select v-if="current_display === 'action_type'"
					class="full-width"
					v-model="action_selected"
					:options="action_list"
					label="Add new action"
					@input="selectRule"
					:error="has_base_error"
					:error-message="base_error_message"
					@popup-hide="openItemsAction()"
				/>
				<q-select v-if="current_display === 'action_fg'"
					class="full-width"
					v-model="action_fg_selection"
					:options="fg_options"
					:label="title_text"
					ref="action_fg"
					@popup-hide="openItemsAction()"
					:error="has_base_error"
					:error-message="base_error_message"
					:option-label="(item) => item.title"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
					</template>
				</q-select>

				<q-select v-if="current_display === 'action_field'"
					class="full-width"
					v-model="action_field_selection"
					:options="field_options"
					:label="title_text"
					ref="action_field"
					@popup-hide="openItemsAction()"
					:error="has_base_error"
					:error-message="base_error_message"
					:option-label="(item) => item.label"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
					</template>
				</q-select>

				<q-select v-if="current_display === 'action_value_multiselect'"
					class="full-width"
					v-model="action_value_selection"
					:options="value_options"
					:label="action_field_selection.label + ' / ' + title_text"
					ref="action_value_multiselect"
					@popup-hide="openItemsAction()"
					:error="has_action_select_error"
					:error-message="action_select_error_message"
					:option-label="(item) => item.value"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
					</template>
				</q-select>

				<div class="text-left full-width row " v-if="current_display == 'action_value_checkbox'">
					<div style="width:50%;" class="flex items-center">
						{{ action_field_selection.label }} / {{ title_text }}
					</div>
					<div style="width:50%;" class="text-right">
						<q-toggle v-model="action_checkbox_value"/>
					</div>
				</div>
				<q-input v-if="current_display == 'action_value_datetime'"
					v-model="action_input_value"
					class="full-width"
					:label="action_field_selection.label + ' / ' + title_text"
					:error="has_action_input_error"
					:error-message="action_input_error_message"
					hint="Format DD/MM/YYYY HH:MM:SS"
					@click="date_time_dialog_action=true"
				>
					<template v-slot:prepend>
						<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog_action=true"/>
					</template>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>

				<!-- DATE -->
				<q-input v-if="current_display == 'action_value_date'"
					v-model="action_input_value"
					class="full-width"
					:label="action_field_selection.label + ' / ' + title_text"
					:error="has_action_input_error"
					:error-message="action_input_error_message"
					hint="Format DD/MM/YYYY"
					@click="show_date_modal_action=true"
				>
					<template v-slot:prepend>
						<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal_action=true"/>
					</template>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>
				<!-- TIME -->
				<q-input v-if="current_display == 'action_value_time'"
					v-model="action_input_value"
					class="full-width"
					:label="action_field_selection.label + ' / ' + title_text"
					:error="has_action_input_error"
					:error-message="action_input_error_message"
					hint="Format HH:MM:SS"
					@click="show_time_modal_action=true"
				>
					<template v-slot:prepend>
						<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal_action=true"/>
					</template>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>
				<!-- NUMBER -->
				<q-input v-if="current_display == 'action_value_number'"
					v-model="action_input_value"
					type="number"
					class="full-width"
					:label="action_field_selection.label + ' / ' + title_text"
					:error="has_action_input_error"
					:error-message="action_input_error_message"
					onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>
				<!-- ATTACHMENT -->
				<div class="text-left full-width row items-center" style="height: 100%;" v-if="current_display == 'action_value_attachment'">
					<div style="width:70%;" class="flex items-center">
						{{ action_field_selection.label }} / {{ title_text }}
					</div>
					<div style="width:30%;" class="text-right">
						<q-toggle v-model="action_attachment_value" :disable="is_inherited"/>
					</div>
				</div>
				<!-- INPUT FIELD -->
				<q-input v-if="current_display == 'action_value_input'"
					v-model="action_input_value"
					class="full-width"
					type="text"
					:label="action_field_selection.label + ' / ' + title_text"
					:error="has_action_input_error"
					:error-message="action_input_error_message"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
					</template>
				</q-input>

				<!-- STATUS -->
				<q-select v-if="current_display == 'action_status'"
					class="full-width"
					v-model="actions_status_selection"
					:options="statuses"
					:label="title_text"
					ref="action_status"
					:error="has_action_status_error"
					:error-message="action_status_error_message"
					@popup-hide="openItemsAction()"
				>
					<template v-slot:append>
						<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
					</template>
				</q-select>
				<div class="full-width" v-if="current_display == 'action_scenario'">
					<q-select v-if="!selected_scenario_type.length"
						class="full-width"
						v-model="selected_scenario_type"
						:options="scenario_types"
						label="Select scenario type"
						ref="action_scenario_type"
						@popup-hide="openItemsAction()"
					>
						<template v-slot:append>
							<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
						</template>
					</q-select>
					<q-select v-else
						class="full-width"
						v-model="actions_scenario_selection"
						:options="scenarios_filtered"
						:label="'Select ' + selected_scenario_type + ' s	cenario' "
						ref="action_scenario_list"
						@popup-hide="openItemsAction()"
						:error="has_action_scenario_error"
						:error-message="action_scenario_error_message"
						:option-label="(item) => item.name"
					>
						<template v-slot:append>
							<q-btn flat icon="o_close" @click.stop="resetValuesAction()" class="cursor-pointer close-btn"/>
						</template>
					</q-select>
				</div>
				<q-btn
					v-if="['action_value_checkbox', 'action_value_attachment'].includes(current_display)"
					round
					flat
					color="dark"
					icon="close"
					size="sm"
					style="height: fit-content;"
					@click="resetValuesAction()"
				/>
				<q-dialog v-model="date_time_dialog_action">
					<div
						class="q-pa-md date-time-container"
						:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
						:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
					>
						<div class="q-gutter-md row items-start date-time">
							<q-date
								v-model="action_input_value"
								mask="DD/MM/YYYY HH:mm:ss"
								color="primary"
							/>
							<q-time
								format24h
								v-model="action_input_value"
								mask="DD/MM/YYYY HH:mm:ss"
								color="primary"
							/>
						</div>
						<div class="q-mt-md flex justify-end full-width">
							<q-btn color="primary" flat label="Close" @click="date_time_dialog_action = false" />
						</div>
					</div>
				</q-dialog>
				<q-dialog v-model="show_time_modal_action">
					<q-time
						format24h
						v-model="action_input_value"
						mask="HH:mm:ss"
						color="primary"
					>
						<div class="row items-center justify-end">
							<q-btn v-close-popup label="Close" color="primary" flat />
						</div>
					</q-time>
				</q-dialog>
				<q-dialog v-model="show_date_modal_action">
					<q-date
						today-btn
						v-model="action_input_value"
						mask="DD/MM/YYYY"
						color="primary"
					>
						<div class="row items-center justify-end">
							<q-btn v-close-popup label="Close" color="primary" flat />
						</div>
					</q-date>
				</q-dialog>
			</q-item>
			<div class="row items-center justify-end">
				<q-btn v-close-popup label="Cancel" color="primary" flat />
				<q-btn
					label="Create"
					@click="action_accept_value()"
					color="primary"
					flat
					:disable="
						current_display.includes('condition_') || (current_display.includes('action_') && 
						(
							!['action_status', 'action_value_input', 'action_value_date', 'action_value_time',
							'action_value_datetime', 'action_value_checkbox', 'action_value_number', 'action_value_attachment',
							'action_value_multiselect', 'action_scenario'].includes(current_display) || 
							(current_display == 'action_scenario' && !selected_scenario_type.length))
						)
					"
				/>
			</div>
		</div>
	</q-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: "RuleAdd",
	props: {
		form_id: Number,
		is_inherited: Boolean,
		scenarios: Array
	},
	data() {
		return {
			condition_fg_selection: {},
			condition_field_selection: {},
			condition_value_selection: {},
			conditions_status_selection: '',
			action_fg_selection: {},
			action_field_selection: {},
			action_value_selection: {},
			actions_status_selection: '',
			actions_scenario_selection: {},
			are_scenarios_real: false,
			condition_checkbox_value: true,
			condition_attachment_value: true,
			condition_input_value: "",
			action_checkbox_value: true,
			action_attachment_value: true,
			action_input_value: "",
			field_options: [],
			value_options: [],
			field_selection: {},
			value_selection: {},
			checkbox_value: true,
			input_value: "",
			show_text: false,
			expanded: false,
			selected_scenario_type: '',
			current_display: "condition_type",
			rule_object: { form: this.form_id },
			statuses: ['new', 'active', 'finished'],
			conditions_list: ['Field', 'Status'],
			action_list: ['Scenario'],
			scenario_types: [],
			selected_condition: '',
			action_selected: '',
			date_time_dialog: false,
			show_date_modal: false,
			show_time_modal: false,
			date_time_dialog_action: false,
			show_date_modal_action: false,
			show_time_modal_action: false,
			step: 1,
			last_condition: '',
			is_condition_readonly: false,
			has_base_error: false,
			base_error_message: '',
			has_condition_status_error: false,
			has_input_error: false,
			has_select_error: false,
			condition_status_error_message: '',
			input_error_message: '',
			select_error_message: '',
			has_action_input_error: false,
			has_action_select_error: false,
			has_action_status_error: false,
			has_action_scenario_error: false,
			action_input_error_message: '',
			action_status_error_message: '',
			action_select_error_message: '',
			action_scenario_error_message: '',
		}
	},
	computed: {
		...mapGetters(['field_groups', 'current_CG', 'logged_user']),
		title_text: function() {
			if (this.current_display == "condition_type" ) {
				return "Select Type"
			}
			if (["condition_fg"].includes(this.current_display)) { return "Select field group" }
			if (["condition_field"].includes(this.current_display)) { return "Select field" }
			if (this.current_display === 'condition_value_input') { return "Input value" }
			if (this.current_display === 'condition_value_datetime') { return "Select date and time" }
			if (this.current_display === 'condition_value_date') { return "Select date" }
			if (this.current_display === 'condition_value_time') { return "Select time" }
			if (this.current_display === 'condition_value_number') { return "Input value" }
			if (this.current_display === 'condition_value_checkbox') { return "Select value" }
			if (this.current_display === 'condition_value_attachment') { return "Has attachment?" }
			if (["condition_value_multiselect"].includes(this.current_display)) { return "Select value" }
			if (["condition_status"].includes(this.current_display)) {return "Select status"}
			if (this.current_display == "action_type" ) { return "Select type" }
			if (["action_fg"].includes(this.current_display)) { return "Select field group" }
			if (["action_field"].includes(this.current_display)) { return "Select field" }
			if (this.current_display === 'action_value_input') { return "Input value" }
			if (this.current_display === 'action_value_datetime') { return "Select date and time" }
			if (this.current_display === 'action_value_date') { return "Select date" }
			if (this.current_display === 'action_value_time') { return "Select time" }
			if (this.current_display === 'action_value_number') { return "Input value" }
			if (this.current_display === 'action_value_attachment') {return "Has attachment?"}
			if (this.current_display === 'action_value_checkbox') { return "Select value" }
			if (["action_value_multiselect"].includes(this.current_display)) { return "Select value" }
			if (["action_status"].includes(this.current_display)) {return "Select status"}
		},
		fg_options: function() {
			return Object.values(this.field_groups)
		},
		scenarios_filtered: function() {
			return this.scenarios.filter(scenario =>
				scenario.real == this.are_scenarios_real);
		}
	},
	mounted() {
		this.retrieveFieldGroups(this.form_id);
	},
	watch: {
		current_display: function(){
			if (this.current_display.includes('condition_')){
				this.step = 1
			}else if(this.current_display.includes('action_')){
				this.step = 2
			}
		},
		condition_fg_selection: function(new_sel, old_sel) {
			let selection = new_sel
			if (selection == null) { selection = old_sel}
			if (selection.id){
				this.rule_object.conditions[0].field_group = selection.id
				this.current_display = "condition_field";
				this.field_options = selection.field_mappings.map(field_mapping => field_mapping.field)
			}else{
				this.field_options = {}
			}
		},
		condition_field_selection: function(new_sel, old_sel) {
			if (this.current_display  === "condition_field"){
				if (new_sel == null) {new_sel = old_sel}
				this.rule_object.conditions[0].field = new_sel.id
				if (new_sel.field_type === "SINGLESELECT" || new_sel.field_type === "MULTISELECT") {
					this.current_display = "condition_value_multiselect";
					this.value_options = new_sel.options
				}
				else if (new_sel.field_type === "CHECKBOX") { this.current_display = "condition_value_checkbox" }
				else if(new_sel.field_type === "DATETIME"){ this.current_display = "condition_value_datetime" }
				else if(new_sel.field_type === "DATE"){ this.current_display = "condition_value_date" }
				else if(new_sel.field_type === "TIME"){ this.current_display = "condition_value_time" }
				else if(new_sel.field_type === 'NUMERIC'){ this.current_display = "condition_value_number" }
				else if(new_sel.field_type === 'ATTACHMENT'){ this.current_display = "condition_value_attachment"}
				else{ this.current_display = "condition_value_input" }
			}
		},
		action_fg_selection: function(new_sel, old_sel) {
			let selection = new_sel
			if (selection == null) { selection = old_sel }
			if (selection.id){
				this.rule_object.actions[0].field_group = selection.id;
				this.current_display = "action_field";
				this.field_options = selection.field_mappings.map(field_mapping => field_mapping.field)
			}else{
				this.field_options = {}
			}
		},
		action_field_selection: function(new_sel, old_sel) {
			if (this.current_display  === "action_field"){
				if (new_sel == null) {new_sel = old_sel}
				this.rule_object.actions[0].field = new_sel.id;
				if (new_sel.field_type === "SINGLESELECT" || new_sel.field_type === "MULTISELECT") {
					this.current_display = "action_value_multiselect";
					this.value_options = new_sel.options
				}
				else if (new_sel.field_type === "CHECKBOX") { this.current_display = "action_value_checkbox" }
				else if(new_sel.field_type === "DATETIME"){ this.current_display = "action_value_datetime" }
				else if(new_sel.field_type === "DATE"){ this.current_display = "action_value_date" }
				else if(new_sel.field_type === "TIME"){ this.current_display = "action_value_time" }
				else if(new_sel.field_type === 'NUMERIC'){ this.current_display = "action_value_number" }
				else if(new_sel.field_type === 'ATTACHMENT'){ this.current_display = "action_value_attachment" }
				else{ this.current_display = "action_value_input" }
			}
		},
	},
	methods: {
		...mapActions(['retrieveFieldGroups']),
		add_new_rule() {
			this.$emit("newRule", this.rule_object);
			this.rule_object = {form: this.form_id}
		},
		selectCondition(){
			if (this.selected_condition == 'Field'){
				this.conditionFieldClicked()
			}else if(this.selected_condition == 'Status'){
				this.conditionStatusClicked()
			}
		},
		conditionFieldClicked() {
			this.rule_object.conditions = [{condition_type: "Field"}]
			this.current_display = "condition_fg";
		},
		conditionStatusClicked() {
			this.rule_object.conditions = [{condition_type: "Status"}]
			this.current_display = "condition_status";
		},
		selectRule(){
			if (this.action_selected === 'Field'){ this.actionFieldClicked() }
			else if(this.action_selected === 'Status'){ this.actionStatusClicked() }
			else if(this.action_selected === 'Scenario'){	this.actionScenarioClicked() }
		},
		actionFieldClicked() {
			this.rule_object.actions = [{action_type: "Field"}]
			this.current_display = "action_fg";
		},
		actionStatusClicked() {
			this.rule_object.actions = [{action_type: "Status"}];
			this.current_display = "action_status";
		},
		actionScenarioClicked() {
			this.rule_object.actions = [{action_type: "Scenario"}];
			this.current_display = "action_scenario";
		},
		condition_accept_value() {
			this.resetConditionErrors()
			if (this.hasConditionError()){ return }
			if (this.current_display === "condition_value_multiselect" && this.condition_value_selection ){
				this.rule_object.conditions[0].values = [this.condition_value_selection.id];
			}else if (this.current_display === "condition_status" && this.conditions_status_selection){
				this.rule_object.conditions[0].status = this.conditions_status_selection;
			}else if (this.current_display == "condition_value_checkbox") {
				this.rule_object.conditions[0].values = this.condition_checkbox_value;
			}else if (this.current_display == "condition_value_attachment") {
				this.rule_object.conditions[0].values = this.condition_attachment_value;
			}else if (
				["condition_value_input", "condition_value_date", "condition_value_time",
				"condition_value_datetime", "condition_value_number"].includes(this.current_display) &&
				this.condition_input_value
			) {
				this.rule_object.conditions[0].values = this.condition_input_value;
			}
			this.last_condition = this.current_display
			this.is_condition_readonly = true
			this.current_display = "action_type";
		},
		hasConditionError(){
			if (this.current_display === 'condition_status' && !this.conditions_status_selection.length){
				this.has_condition_status_error = true
				this.condition_status_error_message = 'Please select a value'
				return true
			}
			if (
				['condition_value_input', 'condition_value_date', 'condition_value_time',
				'condition_value_datetime','condition_value_number'].includes(this.current_display)
				&& !this.condition_input_value
			){
				this.has_input_error = true
				this.input_error_message = 'Please enter a value'
				return true
			}
			if (this.current_display === 'condition_value_multiselect' && !this.condition_value_selection.value){
				this.has_select_error = true
				this.select_error_message = 'Please select a value'
				return true
			}
			return false
		},
		resetConditionErrors(){
			this.has_condition_status_error = this.has_input_error = this.has_select_error = false
			this.condition_status_error_message = this.input_error_message = this.select_error_message = ''
		},
		action_accept_value() {
			this.resetActionErrors()
			if(this.hasActionError()){ return }
			if (this.current_display === "action_value_multiselect" && this.action_value_selection ){
				this.rule_object.actions[0].values = [this.action_value_selection.id];
			}else if (this.current_display === "action_status" && this.actions_status_selection){
				this.rule_object.actions[0].incident_state = this.actions_status_selection;
			}else if (this.current_display == "action_value_checkbox") {
				this.rule_object.actions[0].values = this.action_checkbox_value;
			}else if(this.current_display === 'action_value_attachment'){
				this.rule_object.actions[0].values = this.action_attachment_value;
			}else if(this.current_display === "action_scenario" && this.actions_scenario_selection){
				this.rule_object.actions[0].scenario = this.actions_scenario_selection.id;
			}else if (["action_value_input", "action_value_date", "action_value_time", "action_value_datetime",
			"action_value_number"].includes(this.current_display) && this.action_input_value) {
				this.rule_object.actions[0].values = this.action_input_value;
			}
			this.add_new_rule();
		},
		hasActionError(){
			if (this.current_display === 'action_status' && !this.actions_status_selection.length){
				this.has_action_status_error = true
				this.action_status_error_message = 'Please select a value'
				return true
			}
			if (
				['action_value_input', 'action_value_date', 'action_value_time',
				'action_value_datetime','action_value_number'].includes(this.current_display)
				&& !this.action_input_value
			){
				this.has_action_input_error = true
				this.action_input_error_message = 'Please enter a value'
				return true
			}
			if (this.current_display === 'action_value_multiselect' && !this.action_value_selection.value){
				this.has_action_select_error = true
				this.action_select_error_message = 'Please select a value'
				return true
			}
			if (this.current_display === 'action_scenario' && this.selected_scenario_type.length && !this.actions_scenario_selection.id){
				this.action_scenario_error_message = 'Please select a scenario'
				this.has_action_scenario_error = true
				return true
			}
			return false
		},
		resetActionErrors(){
			this.has_action_status_error = this.has_action_input_error = this.has_action_select_error = this.action_select_error_message = false
			this.action_status_error_message = this.action_input_error_message = this.action_select_error_message = this.action_scenario_error_message = ''
		},
		toggle_scenario_type() {
			this.are_scenarios_real = !this.are_scenarios_real;
		},
		resetValuesCondition(){
			this.condition_checkbox_value = true
			this.condition_attachment_value = true
			this.condition_fg_selection = {}
			this.condition_value_selection = {}
			this.condition_field_selection = {}
			this.selected_condition = ""
			this.condition_input_value = ""
			this.conditions_status_selection = ""
			this.current_display = "condition_type"
		},
		resetValuesAction(){
			this.action_checkbox_value = true
			this.action_attachment_value = true
			this.actions_scenario_selection = {};
			this.action_fg_selection = {}
			this.action_value_selection = {}
			this.action_field_selection = {}
			this.action_selected = ""
			this.action_input_value = ""
			this.selected_scenario_type = ""
			this.actions_status_selection = ""
			this.current_display = "action_type"
		},
		openItemsCondition(){
			if (!["condition_type", "condition_value_checkbox", "condition_value_input", "condition_value_date",
			"condition_value_time", "condition_value_datetime", "condition_value_number", "condition_value_attachment"].includes(this.current_display)){
				if (this.current_display == 'condition_status' && this.conditions_status_selection){ return }
				if (this.current_display == 'condition_value_multiselect' && this.condition_value_selection.value){ return }
				this.$refs[this.current_display].showPopup()
			}
		},
		openItemsAction(){
			if (this.current_display === 'action_scenario'){
				if(this.current_display == 'action_scenario' && this.actions_scenario_selection.id){
					return
				}else if (!this.selected_scenario_type.length){
					this.$refs['action_scenario_type'].showPopup()
				}else{
					this.$refs['action_scenario_list'].showPopup()
				}
			}else if (!["action_type", "action_value_checkbox", "action_value_input", "action_value_date",
			"action_value_time", "action_value_datetime", "action_value_number", "action_value_attachment"].includes(this.current_display)){
				if (this.current_display == 'action_value_multiselect' && this.action_value_selection.value){ return }
				if (this.current_display == 'action_status' && this.actions_status_selection){ return }
				this.$refs[this.current_display].showPopup()
			}
		},
	},
	created() {
		if (this.logged_user.customer_permission === "ADMIN"){
			this.action_list.unshift('Field', 'Status')
		}
		this.scenario_types.push(
			this.current_CG.customer_group.session_type_name.test_scenario_name,
			this.current_CG.customer_group.session_type_name.real_scenario_name
		)
	},
}
</script>
<style lang="scss">
	.q-stepper__step-inner {
		display: none;
	}
	.q-stepper__tab{
		padding: 0px;
	}
</style>
<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>