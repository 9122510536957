<template>
	<div>
		<component
			:is='getTag()'
			class="q-pa-xs"
			v-if="selected_rule"
			id="sticky-sidebar"
			:class="$q.screen.gt.xs ? 'sticky-sidebar' : 'sticky-footer' "
		>
			<div class="sidebar-items">
				<q-btn
					v-if='$q.screen.gt.xs && this.rules_local.length && form_ready && !form.is_inherited'
					flat
					round
					color="dark"
					icon="o_save"
					class="cursor-pointer"
					:disabled="is_disabled"
					@click="saveRule()"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Save rule
					</q-tooltip>
				</q-btn>
				<q-btn
					v-if="$q.screen.lt.sm && form_ready && !form.is_inherited && logged_user.customer_permission == 'ADMIN'"
					flat
					round
					icon="o_save"
					color="dark"
					@click="saveRules()"
					class="cursor-pointer"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Save all changes
					</q-tooltip>
				</q-btn>
				<q-btn
					v-if="$q.screen.lt.sm && form_ready && !form.is_inherited && logged_user.customer_permission == 'OPERATOR'"
					flat
					round
					icon="o_save"
					color="dark"
					@click="saveRule()"
					:disabled="is_disabled"
					class="cursor-pointer"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Save rule
					</q-tooltip>
				</q-btn>
				<q-btn
					v-if="this.rules_local.length && form_ready && !form.is_inherited"
					flat
					round
					color="dark"
					icon="o_delete"
					class="cursor-pointer"
					:disable="is_disabled"
					@click="show_delete_dialog = true"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Delete rule
					</q-tooltip>
				</q-btn>
				<q-btn
					v-if="form_ready && !form.is_inherited"
					flat
					round
					color="dark"
					icon="o_splitscreen"
					class="cursor-pointer"
					@click="add_new_rule = true"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Add rule
					</q-tooltip>
				</q-btn>
				<q-btn
					flat
					round
					color="dark"
					icon="arrow_back"
					class="cursor-pointer"
					:to="{ name: 'Incident Rules'}"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Back
					</q-tooltip>
				</q-btn>
			</div>
		</component>
		<div v-if="rules_local && form_ready" :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'" id="wrapper">
			<div :class="{'q-pr-xl' : $q.screen.gt.xs}">
				<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
					Rules for {{ form_name }}
				</h1>
				<div v-for="(rule, index) in rules_local" :key="rule.order">
					<Rule
						:rule="rule"
						:rule_index="index"
						:form_id="form_id"
						:is_inherited="form.is_inherited"
						:scenarios="scenarios"
						:counter="index"
						:selected_rule="selected_rule"
						:error="error"
						@removeAddedField="removeAddedField"
						@ruleUpdated="ruleUpdated"
						@ruleDeleted="ruleDeleted"
						@rule-selected="ruleSelected"
					/>
				</div>
			</div>
			<q-card :class="{'q-mr-xl' : $q.screen.gt.xs}">
				<q-card-section class="q-pa-none">
					<q-item class="full-width q-pa-none">
						<q-input
							outlined
							v-model="history_of_the_rule"
							label="History"
							type="textarea"
							class="full-width cursor-pointer"
							readonly
							@click.prevent="refresh_history()"
						>
							<template v-slot:append>
								<q-avatar>
									<q-btn
										flat
										round
										color="dark"
										icon="refresh"
										@click.prevent="refresh_history()"
									/>
								</q-avatar>
							</template>	
						</q-input>
					</q-item>
				</q-card-section>
			</q-card>
		</div>
		<div class="field-fab-wrapper q-mt-md" v-if="
			$q.screen.gt.xs && form_ready && !form.is_inherited && logged_user.customer_permission !== 'OPERATOR'"
		>
			<q-btn
				size="md"
				padding="md lg"
				color="primary"
				icon="save"
				label="Save all rules"
				rounded
				@click="saveRules()"
			/>
		</div>
		<q-dialog v-model="show_delete_dialog">
			<q-card>
				<q-card-section>
					<div>Are you sure you want to delete this rule?</div>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn v-close-popup label="Close" color="primary" flat />
					<q-btn v-close-popup label="Delete" @click="removeRule()" color="primary" flat />
				</q-card-actions>
			</q-card>
		</q-dialog>
		<q-dialog v-model="add_new_rule">
			<RuleAdd
				:form_id="form_id"
				:is_inherited="this.form.is_inherited"
				class="add-rule"
				@newRule="newRule"
				:scenarios="scenarios"
			/>
		</q-dialog>
	</div>
</template>

<script>
import Rule from '@/components/incidents/rules/Rule.vue';
import RuleAdd from '@/components/incidents/rules/RuleAdd.vue';
import Utils from '@/services/utils.js'
import ScenariosAPI from '@/services/api/scenarios.js';
import RulesAPI from '@/services/api/incidentRules.js';
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "RulesList",
	components: {
		Rule,
		RuleAdd,
		draggable,

	},
	data() {
		return {
			rules_local: [],
			add_new_rule: false,
			type_status: "Status",
			type_field: "Field",
			new_rule: {
				conditions: {},
				actions: {},
				form: ""
			},
			form_name: '',
			scenarios: [],
			history_of_the_rule: "",
			is_disabled: false,
			form: {},
			form_id: 0,
			selected_rule: {},
			show_delete_dialog: false,
			add_new_rule: false,
			form_ready: false,
			error: {
				has_error: false,
				error_message: '',
				error_field_name: '',
				error_type: '',
				error_value: null
			}
		};
	},
	computed: {
		...mapGetters(['rules', 'logged_user', 'current_CG']),
		rules_modified: function() {
			let form_id = 0;
			let modified_rules = this.rules_local.map(function(rule) {
				form_id = rule.form;
				return rule.modified || rule.deleted;
			});

			return { [form_id]: modified_rules.some(x => !!x) };
		}
	},
	methods: {
		...mapActions([
			"createRule",
			"listRules",
			"updateRules",
			"deleteRule",
			"getAllFields",
			"retrieveFieldGroups",
			"getForm",
		]),
		async saveRules() {
			this.error = {
				has_error: false,
				error_message: '',
				error_field_name: '',
				error_type: '',
				error_value: null
			}
			if (!this.is_disabled && !this.is_inherited) {
				for (let rule of this.rules_local) {
					for(let i = 0; i < rule.conditions.length; i++){
						if(rule.conditions[i].added){
							delete rule.conditions[i].added;
						}
						if(rule.conditions[i].modified != undefined){
							delete rule.conditions[i].modified;
						}
						if(rule.conditions[i].deleted){
							rule.conditions.splice(i,1)
							i--;
						}
					}
					for(let i = 0; i < rule.actions.length; i++){
						if(rule.actions[i].added){
							delete rule.actions[i].added;
						}
						if(rule.actions[i].modified != undefined){
							delete rule.actions[i].modified;
						}
						if(rule.actions[i].deleted){
							rule.actions.splice(i,1)
							i--;
						}
					}
					if(rule.conditions.length == 0 || rule.actions.length == 0){
						this.notify("Please select at least one conditions and one action for each rule.")
						return
					}
					for (let condition of rule.conditions){
						if (condition.condition_type == 'Status' && !condition.status){
							if (typeof(condition.values) !== 'boolean'){
								this.notify("Please enter a value for all conditions.")
								return
							}
						}
					}
					for (let action of rule.actions){
						if (
							(action.action_type == 'Status' && !action.incident_state) || 
							(action.action_type == 'Scenario' && !action.scenario)
						){
							if (typeof(action.values) !== 'boolean'){
								this.notify("Please enter a value for all actions.")
								return
							}
						}
					}
					if (rule.deleted) {
						await this.deleteRule(rule);
					} else {
						let response = await this.updateRules(rule);
						if (response.error){
							if (response.error_field){
								this.error = {
									'has_error': true,
									'error_message': response.error_message,
									'error_field_name': response.error_field,
									'error_type': response.error_field_type,
									'error_value': response.error_field_value
								}
								this.notify('The rule could not be updated. Please fix the errors.')
								return
							}
							this.notify(response.error_message)
							return
						}
					}
				}
				this.notify('Rules updated successfully.')
			}
		},
		getTag(){
			if (window.innerWidth > 599){
				return 'q-card'
			}else{
				return 'q-footer'
			}
		},

		async saveRule(){
			this.error = {
				has_error: false,
				error_message: '',
				error_field_name: '',
				error_type: '',
				error_value: null
			}
			if(!this.is_disabled && !this.is_inherited) {
				let rule_index = 0
				for (let index in this.rules_local){
					if (this.selected_rule.id == this.rules_local[index].id){
						rule_index = index
						break
					}
				}
				for(let i = 0; i < this.selected_rule.conditions.length; i++){
					if(this.selected_rule.conditions[i].added){
						delete this.selected_rule.conditions[i].added;
					}
					if(this.selected_rule.conditions[i].modified != undefined){
						delete this.selected_rule.conditions[i].modified;
					}
					if(this.selected_rule.conditions[i].deleted){
						this.selected_rule.conditions.splice(i,1)
						i--;
					}
				}
				for(let i = 0; i < this.selected_rule.actions.length; i++){
					if(this.selected_rule.actions[i].added){
						delete this.selected_rule.actions[i].added;
					}
					if(this.selected_rule.actions[i].modified != undefined){
						delete this.selected_rule.actions[i].modified;
					}
					if(this.selected_rule.actions[i].deleted){
						this.selected_rule.actions.splice(i,1)
						i--;
					}
				}
				for (let condition of this.selected_rule.conditions){
					if (condition.condition_type == 'Status' && !condition.status){
						if (typeof(condition.values) !== 'boolean'){
							this.notify("Please enter a value for all conditions.")
							return
						}
					}
				}
				for (let action of this.selected_rule.actions){
					if (
						(action.action_type == 'Status' && !action.incident_state) || 
						(action.action_type == 'Scenario' && !action.scenario)
					){
						if (typeof(action.values) !== 'boolean'){
							this.notify("Please enter a value for all actions.")
							return
						}
					}
				}
				if(this.selected_rule.conditions.length == 0 || this.selected_rule.actions.length == 0){
					this.notify("Please select at least one conditions and one action for each rule.")
					return
				}
				let response = await this.updateRules(this.selected_rule);
				if (response.error){
					if (response.error_field){
						this.error = {
							'has_error': true,
							'error_message': response.error_message,
							'error_field_name': response.error_field,
							'error_type': response.error_field_type,
							'error_value': response.error_field_value
						}
						this.notify('The rule could not be updated. Please fix the errors.')
						return
					}
					this.notify(response.error_message)
					return
				}
				let obj = {}
				obj['index'] = rule_index;
				obj['rule'] = this.selected_rule;
				this.ruleUpdated(obj);
				this.notify('Rule updated successfully.')
			}
		},

		async ruleUpdated(obj){
			this.error = {
				has_error: false,
				error_message: '',
				error_field_name: '',
				error_type: '',
				error_value: null
			}
			for(let i in obj.rule.conditions){
				if(obj.rule.conditions[i].modified != undefined){
					this.$delete(obj.rule.conditions[i], 'modified')
				}
			}
			for(let i in obj.rule.actions){
				if(obj.rule.actions[i].modified != undefined){
					this.$delete(obj.rule.actions[i], 'modified')
				}
			}
			let response = await this.updateRules(obj.rule);
			if (response.error){
				if (response.error_field){
					this.error = {
						'has_error': true,
						'error_message': response.error_message,
						'error_field_name': response.error_field,
						'error_type': response.error_field_type,
						'error_value': response.error_field_value
					}
					this.notify('The rule could not be updated. Please fix the errors.')
					return
				}
				this.notify(response.error_message)
				return
			}
			this.$set(this.rules_local, obj.index, obj.rule)
		},
		ruleDeleted(rule_index){
			this.rules_local.splice(rule_index,1);
		},
		async newRule(rule) {
			this.add_new_rule = false
			this.$q.loading.show({
				delay: 400,
			})

			let response_create = await this.createRule(rule);
			if (response_create.error){
				this.notify(response_create.error_message + ' ' + 'Field: ' + response_create.error_field)
				this.add_new_rule = false
				this.$q.loading.hide()
				return
			}
			await this.listRules(rule.form);
			this.rules_local = Utils.deepCopy(this.rules);
			this.$q.loading.hide()
			this.selected_rule = this.rules_local[this.rules_local.length - 1]
			let rule_id = this.selected_rule.id
			setTimeout(function() {
				if (window.innerWidth > 599){
					let element = document.getElementById('rule_' + rule_id)
					let sidebar = document.getElementById('sticky-sidebar')
					element.scrollIntoView({
						behavior: 'auto',
						block: 'start',
						inline: 'start'
					});
					sidebar.style.top = `${150 + element.getBoundingClientRect().top}px`;
				}else{
					window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
				}
			}, 100);
			this.notify("Rule successfully created.")
		},
		removeAddedField(rule_index) {
			this.rules_local.splice(rule_index, 1);
		},
		// SHOW / HIDE logic
		async listAllRules(form_id) {
			await this.listRules(form_id);
			this.rules_local = Utils.deepCopy(this.rules);
			this.load_rule_logs(form_id)
		},
		format_rule_log(log) {
			let rule_logs_string = log.executed_on + "   ";
			if (log.action_type === "status") {
				rule_logs_string += `Status changed from "${log.details.old_value}" to "${log.details.new_value}"`;
			}
			else if (log.action_type === "field") {
				rule_logs_string += `The Field "${log.details.field_label}" in the Field Group "${log.details.field_group_name}" was changed from "${log.details.old_value}" to "${log.details.new_value}"`;
			}
			else if (log.action_type === "session") {
				rule_logs_string += `Session ${log.details.session_id} was activated successfully`;
			}
			rule_logs_string += "\n"
			return rule_logs_string;
		},
		async load_rule_logs(form_id) {
			let rule_logs = await RulesAPI.listRuleLogs(form_id);
			let rule_logs_string = "";
			if(!rule_logs.error){
				rule_logs.forEach(log => {
					rule_logs_string += this.format_rule_log(log);
				});
			}

			this.history_of_the_rule = rule_logs_string;

		},
		async refresh_history() {
			this.history_of_the_rule = "Refreshing...";
			setTimeout(() => {
				this.load_rule_logs(this.form_id);
			}, 1000);
		},
		ruleSelected(rule_id){
			let id = rule_id.split('_').pop()
			for (let item of this.rules_local){
				if (id == item.id){
					this.selected_rule = item
					if (window.innerWidth > 599){
						let element = document.getElementById(rule_id)
						let sidebar = document.getElementById('sticky-sidebar')
						element.scrollIntoView({
							behavior: 'auto',
							block: 'start',
							inline: 'start'
						});
						sidebar.style.top = `${150 + element.getBoundingClientRect().top}px`;
					}
					break
				}
			}
		},
		async removeRule() {
			if (this.selected_rule){
				await this.deleteRule(this.selected_rule);
				this.notify("Rule successfully deleted.")
				this.show_delete_dialog = false;
				await this.listRules(this.form_id);
				this.rules_local = Utils.deepCopy(this.rules);
				if (this.rules_local.length){
					this.selected_rule = this.rules_local[0]
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}else{
					if (!this.form.is_inherited && this.logged_user.customer_permission == 'ADMIN'){
						this.add_new_rule = true
					}
				}
			}
		},
		notify(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		}
	},
	watch:{
		selected_rule: function(){
			if (this.selected_rule && this.logged_user.customer_permission === 'OPERATOR'){
				this.is_disabled = false
				for (let action of this.selected_rule.actions) {
					if (action.action_type !== 'Scenario') {
						this.is_disabled = true
						break
					}
				}
			}
		}
	},
	async created() {
		this.$q.loading.show({
			delay: 400,
		})
		this.form_id = Number(this.$route.params.id)
		this.form = await this.getForm(this.form_id);
		this.form = this.form.data
		this.form_name = this.form.name
		await this.getAllFields();
		await this.load_rule_logs(this.form_id);
		let scenarios = await ScenariosAPI.getScenarios();
		if (scenarios.hasOwnProperty('results')) {
			this.scenarios = scenarios.results.scenarios;
		}
		await this.listAllRules(this.form_id)
		await this.retrieveFieldGroups(this.form_id)
		this.form.is_inherited = this.current_CG.customer_group.parent && this.form.customer_group !== this.logged_user.customer_group
		if (this.rules_local.length){
			this.selected_rule = this.rules_local[0]
		}else{
			if (!this.form.is_inherited && this.logged_user.customer_permission !== 'OPERATOR'){
				this.add_new_rule = true
			}
		}
		this.form_ready = true
		this.$q.loading.hide()
	}
};
</script>
<style lang="scss" scoped>
.sticky-sidebar{
	display: inline-block;
	margin-bottom: 0;
	transform-origin: 0% 0%; 
	position: sticky;
	top: 145px;
	right: 30px;
	z-index: 5;
	float: right;
}
.sticky-footer{
	background: #fff;
	border-radius: 0.375rem;
	margin-left: auto;
	margin-right: auto;
}
.sidebar-items{
	display: flex;
	flex-direction: column;
	@media(max-width: 599px){
		flex-direction: row;
		justify-content: space-evenly;
	}
}
.field-fab-wrapper {
	position: sticky;
	bottom: 20px;
	z-index: 5;
	width: fit-content;
	margin: 0 auto;
}
</style>
