import axios from 'axios';
import store from '@/store/store.js'

export default {
	async listRuleLogs(form_id) {
		if (form_id) {
			try {
				const response = await axios.get(`/api/incidents/form/${form_id}/rule_logs/`);
				store.dispatch('set_rules_errors', "");
				return response.data;
			} catch(err) {
				const response = {
					error: true,
					error_status: err.response.status,
					error_message: err.response.data,
				};
				if(response.error_status==500){
					store.dispatch('set_rules_errors', "An error occured. Please contact your administrator.");
				}else{
					store.dispatch('set_rules_errors', response.error_message)
				}
				return response;
			}
		}
		else {
			return [];
		}
	},
}